.custom-card-header {
  background-color: rgba(86, 145, 246, .078) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1.25rem;
  height: 59px;

  h2 {
    margin: 9px 0px;
  }
}
