.pt-6 {
  padding-top: 6rem !important;
}
.pointer {
  cursor: pointer;
}
.bgc-f3 {
  background-color: #f3f3f3;
}
.dark-grey-text {
  color: #504d4d !important;
}
.display-hidden {
  visibility: hidden;
}
.card, .card-body {
  box-shadow: none !important;
}
.fixed-action-btn {
  transition: height 1s ease-out;
}
.fixed-action-btn:not(.active) {
  height: 100px;
}
.go-home {
  padding-top: 20px;
  text-align: center;
  transition: 0.3s;
  font-weight: 800;
}
.go-home:hover {
  cursor: pointer;
  background-color: #ecebeb;
}
.fixed-action-btn {
  bottom: 5px !important;
  right: 5px !important;

  ul.list-unstyled {
    height: 260px !important;

    li {
      margin-bottom: 0px !important;
    }
  }
}
.cabinet-wrapper .chip {
  overflow: unset !important;
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
}
.cabinet-wrapper .chip.inactive {
  text-decoration: line-through;
}
.container-stripe {
  position: fixed;
  background-color: #f3f3f3;
  height: 90vh;
  width: 1px;
  top: 80px;
}
.container-stripe.left {
  left: 7px;
}
.container-stripe.right {
  right: 7px;
}
.posts-list-wrapper {
  background-color: #fff;
  padding-bottom: 25px;
  padding-top: 10px;

  .posts-loader {
    width: 90%;
    margin: 50px auto;
  }
}
.posts-list {
  display: grid;
  grid-column-gap: 0em;
  grid-row-gap: 1em;
  grid-template-columns: 1fr;

  .posts-item {
    height: 205px;
    position: relative;
    cursor: pointer;
    display: block;

    .avatar-border {
      right: 0px !important;
    }
    .avatar-border-back {
      right: 0px !important;
    }
  }
}
.posts-item {
  .avatar-border {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0px;
    right: 15px;
    border-top: solid 1px #d4dcdc;
    border-right: solid 1px #d4dcdc;
    -webkit-border-radius: 0 20px 0 0;
    -moz-border-radius: 0 20px 0 0;
    border-radius: 0 20px 0 0;
    z-index: 15;
  }
  .avatar-border-back {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 5;
    background-color: #f4f4f4;
  }
  .avatar-wrapper {
    background-color: #fff;
    -webkit-border-radius: 0px 20px 0px 20px;
    -moz-border-radius: 0px 20px 0px 20px;
    border-radius: 0px 20px 0px 20px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    text-align: center;
    height: 180px;
    width: 100%;
    line-height: 175px;

    .avatar {
      -webkit-border-radius: 0px 20px 0px 20px;
      -moz-border-radius: 0px 20px 0px 20px;
      border-radius: 0px 20px 0px 20px;
      max-height: 180px;
    }
  }
  .title-wrapper {
    cursor: pointer;
    background-color: #adadad;
    -webkit-border-radius: 0px 0px 0px 20px;
    -moz-border-radius: 0px 0px 0px 20px;
    border-radius: 0px 0px 0px 20px;
    margin-top: -25px;
    height: 50px;

    .title-border {
      background-color: #f4f4f4;
      -webkit-border-radius: 0px 0px 0px 20px;
      -moz-border-radius: 0px 0px 0px 20px;
      border-radius: 0px 0px 0px 20px;
      height: 25px;
    }
    .title {
      color: #fff;
      padding-left: 25px;
      line-height: 25px;
      font-weight: 500;
      display: grid;
      grid-template-columns: 1fr 30px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .fa-heart {
        line-height: 25px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
.posts-item.favorite {
  display: block;

  .avatar-border {
    border-top: solid 1px #a9bab9;
    border-right: solid 1px #a9bab9;
  }
  .avatar-border-back {
    background-color: #212b2a;
  }
}
.post-page-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 70px);

  .column-middle {
    background-color: #f3f3f3;
    padding: 5px;
  }
  .column-left, .column-right {
    display: none;
  }
  .post-page {
    height: 100%;
    .card-body {
      height: 100%;
      padding: 10px 0.75rem 20px;
    }
    .content {
      display: grid;
      grid-template-columns: 1fr;

      .images-wrapper {
        display: flex;
        position: relative;

        .image-gallery-slides {
          -webkit-border-radius: 0px 30px 0px 30px;
          -moz-border-radius: 0px 30px 0px 30px;
          border-radius: 0px 30px 0px 30px;
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
          font-size: 2em !important;
          padding: 5;
        }
        .image-gallery-left-nav:before, .image-gallery-right-nav:before {
          color: #504c4d !important;
          text-shadow: none !important;
        }
        .image-gallery-thumbnail.active {
          border-color: #504c4d !important;
        }
        .image-gallery-fullscreen-button::before {
          font-size: 1.5em !important;
          padding: 15px 10px !important;
        }
        .favorite-btn {
          position: absolute;
          right: 20px;
          top: 20px;
          color: #212b2a;
          cursor: pointer;
          z-index: 20;
        }
        .squareTop {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 30px;
          height: 30px;
          background-color: #f3f3f3;
          z-index: 0;
        }
        .borderTop {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 70%;
          height: 100%;
          border-top: solid #f3f3f3 1px;
          border-right: solid #f3f3f3 1px;
          background-color: #fff;
          -webkit-border-radius: 0px 30px 0px 0px;
          -moz-border-radius: 0px 30px 0px 0px;
          border-radius: 0px 30px 0px 0px;
          z-index: 0;
        }
        .borderBottom {
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 70%;
          height: 100%;
          border-bottom: solid #f3f3f3 1px;
          border-left: solid #f3f3f3 1px;
          background-color: #fff;
          -webkit-border-radius: 0px 0px 0px 30px;
          -moz-border-radius: 0px 0px 0px 30px;
          border-radius: 0px 0px 0px 30px;
        }
      }
      .info-wrapper {
        margin-top: 10px;
        position: relative;

        .shop {
          line-height: 60px;

          .logo {
            max-height: 40px;
          }
          .title {
            padding-left: 5px;
            font-size: 15px;
            color: #585657;
            display: none;
          }
        }
        .title {
          font-size: 15px;
          margin: 0px 0px 0px 10px;
          color: #1b1b1b;
          letter-spacing: 1px;
        }
        .price {
          margin-left: 10px;
          font-size: 11px;
        }
        .characteristic-title {
          padding: 5px 20px 5px 10px;
          font-size: 15px;
          background-color: #f4f4f4;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          color: #161616;
          letter-spacing: 1px;
          font-weight: 800;

          .youtube-link {
            float: right;
            font-size: 20px;
            color: #e80000;
            margin-top: -2px;
            margin-right: -10px;
          }
        }
        .characteristic-items {
          margin-left: 0px;
          font-size: 13px;
          color: #5f5e5e;
        }
        .color-item {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .action-buttons {
          position: absolute;
          top: 10px;
          right: 0px;
          width: 160px;
          text-align: right;

          .shop-link {
            padding: 5px 10px;
            margin-left: 5px;
          }
          .file-link {
            background-color: #fafbe3;
            padding: 5px 10px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            text-align: center;
            color: #1e1e1e;
            display: inline-block;
            margin-top: 10px;

            .full {
              display: none;
            }
          }
          .file-link:hover {
            background-color: #f3f5c8;
            color: #191919;
          }
        }
      }
    }
  }
}
.post-page-wrapper.favorite {
  .squareTop {
    background-color: #212b2a !important;
  }
  .borderTop {
    border-top: solid #212b2a 1px !important;
    border-right: solid #212b2a 1px !important;
  }
  .borderBottom {
    border-bottom: solid #212b2a 1px !important;
    border-left: solid #212b2a 1px !important;
  }

  .slider-border-left, .slider-border-right {
    background-color: #212b2a;
  }
}
.navigation-wrapper {
  padding: 8px 0px;
  background-color: #fff;

  .navigation {
    display: grid;
    grid-template-columns: 70px 1fr 100px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .filters-button-collapse {
      background-color: #f3f3f3;
      margin: 0 15px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 110;
      cursor: pointer;
      width: 40px;
      height: 45px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #504d4d;
        border-radius: 4px;
        opacity: 1;
        left: 25%;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 15px;
      }
      span:nth-child(2), span:nth-child(3) {
        top: 21px;
      }
      span:nth-child(4) {
        top: 27px;
      }
    }
    .filters-button-collapse.active {
      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
    .logo-wrapper {
      display: none;
    }
    .search-wrapper {
      position: relative;

      .fa-search {
        position: absolute;
        font-size: 23px;
        color: #dedede;
        left: 10px;
        top: 10px;
      }
      .chips {
        background-color: #f3f3f3;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        border-bottom: none;
        padding: 6px 10px 0px 45px;
        margin: 0px;

        .chip {
          margin-bottom: 0.4rem !important;
          margin-right: 0.4rem !important;
        }
        .Ripple-parent {
          overflow: unset !important;
        }
        .inactive {
          text-decoration: line-through;
        }
      }
      .chips.focus {
        border-bottom: none !important;
        box-shadow: none !important;
      }
    }
    .action-wrapper {
      text-align: center;

      .fa-heart, .fa-user {
        font-size: 25px;
        color: #504c4d;
        padding: 8px 10px;
        cursor: pointer;
      }
    }
  }
  .border-middle {
    margin: 8px 50px 0px 70px;
    height: 1px;
    background-color: #f3f3f3;
  }
  .filters-bottom-wrapper {
    margin: 0 15px;
    display: none;

    .filters-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 1em;
      grid-row-gap: 1em;

      .filter-select-wrapper {
        position: relative;
        height: 50px;

        .filter-layer-click-handler {
          position: absolute;
          top: 0px;
          height: 50px;
          width: 100%;
          z-index: 10000;
          cursor: pointer;
        }
      }
      .filter-select-wrapper.active {
        input.select-dropdown {
          color: #f3f3f3;
        }
      }
      .select-wrapper {
        margin-top: 0px;
        padding: 15px 20px 0px;
        background-color: #f3f3f3;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        height: 50px;

        input.select-dropdown {
          position: absolute;
          top: 0px;
          left: 0px;
          margin: 0px;
          height: 30px;
          padding: 20px 10% 0px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          width: 80%;
          transition:all 400ms ease;
        }
        .select-dropdown, .select-dropdown:focus {
          box-shadow: none !important;
          border: none !important;
        }
        .mdb-main-label {
          font-size: 12px !important;
          text-transform: uppercase;
          left: 0px !important;
          padding: 0 10%;
        }
        .mdb-main-label.active {
          top: 20px;
        }
        .caret {
          right: 15px !important;
          top: 15px;
        }
        .dropdown-content {
          left: 0px;
          border: none !important;
          top: 40px !important;
          box-shadow: none;
          padding-bottom: 30px;
          background-color: #f3f3f3;
          -webkit-border-radius: 0px 0px 10px 10px;
          -moz-border-radius: 0px 0px 10px 10px;
          border-radius: 0px 0px 10px 10px;
          z-index: 10001;

          li.disabled {
            display: none;
          }
        }
      }
    }
  }
  .filters-bottom-wrapper.active {
    display: block;
    position: absolute;
    z-index: 100;
    left: 0px;
    right: 0px;

    .filters-wrapper {
      position: relative;
      z-index: 100;
      background-color: #fff;
      padding: 15px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    .back {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(0, 0, 0, .3);
      z-index: 90;
    }
  }
}

@media (min-width: 460px) {
  .fixed-action-btn {
    right: 5px !important;
    bottom: 5px !important;
  }
  .posts-list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1em;
  }
}

@media (min-width: 640px) {
  .navigation-wrapper {
    .filters-bottom-wrapper {
      .filters-wrapper {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
      }
    }
  }
  .posts-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 960px) {
  .navigation-wrapper {
    padding: 8px 0px;

    .navigation {
      display: grid;
      grid-template-columns: 280px 1fr 100px;

      .filters-button-collapse {
        display: none;
      }
      .logo-wrapper {
        display: block;
        position: relative;
        padding-left: 100px;
        cursor: pointer;

        .logo {
          height: 60px;
          position: absolute;
          left: 20px;
          top: -5px;
        }
        .title {
          font-size: 29px;
          color: #504d4d;
        }
      }
    }
    .border-middle {
      margin: 8px 70px 8px 100px;
    }
    .filters-bottom-wrapper {
      display: block;
      margin: 0px;

      .filters-wrapper {
        margin: 0 5%;
        height: 50px;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2em;
      }
      .back {
        display: none;
      }
    }
    .filters-bottom-wrapper.active {
      position: relative;

      .filters-wrapper {
        padding: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
      }
    }
  }
  .fixed-action-btn {
    right: 12px !important;
    bottom: 12px !important;
  }
  .container-stripe {
    top: 135px;
  }
  .container-stripe.left {
    left: 22px;
  }
  .container-stripe.right {
    right: 22px;
  }
  .posts-list {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    margin-left: 30px;
    margin-right: 30px;
  }
  .post-page-wrapper {
    min-height: calc(100vh - 128px);

    .column-middle {
      padding: 10px;
    }
    .post-page {
      height: 100%;

      .card-body {
        height: 100%;
        padding: 10px 1.25rem 20px;
      }
      .content {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
        height: 100%;

        .images-wrapper {
          .image-gallery, .image-gallery-content {
            height: 100%;
          }
          .image-gallery-left-nav, .image-gallery-right-nav {
            font-size: 3em !important;
            padding: 0 15px;
          }
          .image-gallery-thumbnails-wrapper.bottom {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
          }
          .favorite-btn {
            font-size: 24px;
          }
        }
        .info-wrapper {
          position: relative;
          height: 100%;
          margin-top: 0px;

          .shop {
            line-height: 70px;

            .logo {
              max-height: 80px;
            }
            .title {
              font-size: 23px;
              padding-left: 30px;
              display: inline;
            }
            .shop-link {
              display: none;
            }
          }
          .title {
            margin: 10px 0 0 20px;
            font-size: 25px;
          }
          .price {
            margin-left: 20px;
            font-size: 1rem;
          }
          .characteristic-title {
            padding: 5px 20px;
            font-size: 1.25rem;

            .youtube-link {
              font-size: 26px;
            }
          }
          .characteristic-items {
            margin-left: 5px;
            font-size: 1rem;
          }
          .action-buttons {
            top: auto;
            bottom: 10px;
            right: 10px;
            width: 100%;
            padding-left: 20px;
            text-align: left;

            .grey-btn {
              margin-right: 10px;
            }
            .shop-link {
              padding: 5px 30px;
              margin-right: 50px;
            }
            .file-link {
              background-color: #fafbe3;
              padding: 5px 30px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
              text-align: center;
              color: #1e1e1e;

              .short {
                display: none;
              }
              .full {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1260px) {
  .navigation-wrapper {
    .filters-bottom-wrapper {
      .filters-wrapper {
        max-width: 1200px;
        margin: 0 8%;
      }
    }
  }
  .container-stripe.left {
    left: 30px;
  }
  .container-stripe.right {
    right: 30px;
  }
  .fixed-action-btn {
    right: 20px !important;
    bottom: 20px !important;
  }
  .posts-list {
    grid-template-columns: repeat(5, 1fr);
    margin-left: 50px;
    margin-right: 50px;
  }
  .post-page-wrapper {
    grid-template-columns: 3.9fr 42.2fr 3.9fr;

    .column-middle {
      padding: 10px 0px;
    }
    .column-left, .column-right {
      display: block;
    }
    .post-page {
      .content {
        grid-column-gap: 2em;
      }
    }
  }
}

/*@media (orientation: portrait) {
}*/
